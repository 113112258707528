import React, { useCallback } from 'react';
import {
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { Article, Post } from 'types';
import Img from 'gatsby-image';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { SelectedArticles } from '../../SelectedArticles';
import { PostsList } from '../../PostsList';
// import { Hormones101 } from '../Hormones101';
import { PostPreview } from '../../PostPreview';
import {
  BlogLayoutContainer,
  FeaturedContainer,
  TopArticlesGridItem,
} from './styles';

interface HomepageLayoutProps {
  primaryPost?: Post;
  topArticles: Article[];
  posts: Post[];
  totalPages: number;
  currentPage: number;
}

export const HomepageLayout: React.FC<HomepageLayoutProps> = ({
  primaryPost,
  topArticles,
  posts,
  totalPages,
  currentPage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  const renderPrimaryPost = useCallback(() => {
    if (!primaryPost) return null;
    const { uid, data } = primaryPost;

    return (
      <PostPreview
        title={data.title.text}
        image={isMobile ? undefined : data.hero_image.fluid}
        path={`/${data.category.document.uid}/${uid}`}
        category={{
          name: data.category.document.data.name,
          path: data.category.document.uid,
        }}
        excerpt={data.stand_first.raw}
        author={data.author.text}
        isPrimary
      />
    );
  }, [primaryPost, isMobile]);

  return (
    <BlogLayoutContainer maxWidth="lg" disableGutters={isMobile}>
      <FeaturedContainer>
        <GridContainer justify={isMobile ? 'center' : 'flex-start'}>
          {isMobile && primaryPost && (
            <GridItem xs={12}>
              <Box marginBottom="37px">
                <Img
                  fluid={{
                    ...primaryPost.data.hero_image.fluid,
                    aspectRatio: 1.7,
                  }}
                />
              </Box>
            </GridItem>
          )}
          <GridItem lg={8} md={7} xs={11}>
            {renderPrimaryPost()}
          </GridItem>
          <TopArticlesGridItem lg={4} md={5} xs={11}>
            <H3 align="left">Top Articles</H3>
            <SelectedArticles articles={topArticles} />
          </TopArticlesGridItem>
        </GridContainer>
      </FeaturedContainer>
      <Container disableGutters={!isMobile}>
        <PostsList
          posts={posts}
          count={totalPages}
          page={currentPage}
          path="/"
        />
      </Container>
      {/*<Hormones101 />*/}
    </BlogLayoutContainer>
  );
};
