import { SeoProps } from '@tuunetech/tuune-components';
import { SEOData, SEODStaticData } from 'types';

export type PostDataProps = {
  body2: Record<string, any>[];
  meta_title: string;
  meta_description: string;
  meta_image: Record<'alt' | 'url', string>;
  hero_image: Record<'alt' | 'url', string>;
};

export const constructSEO = (
  postData: SEOData,
  defaultSeo: SEODStaticData,
  pathName: string,
): SeoProps => {
  const twitterProps =
    postData.body2?.reduce((fields, { primary }) => {
      return { ...fields, ...primary };
    }, {}) || {};

  const SEOPropsFromPrismic: Record<string, any> = {
    meta_title: postData.meta_title,
    meta_description: postData.meta_description,
    meta_image: postData.meta_image,
    ...twitterProps,
  };

  const defaultMetadata = defaultSeo?.siteMetadata || {};

  const generalSEO = {
    title: SEOPropsFromPrismic.meta_title || defaultMetadata.title,
    description:
      SEOPropsFromPrismic.meta_description || defaultMetadata.description,
    url: pathName
      ? `${defaultMetadata.siteUrl}${pathName}`
      : defaultMetadata.siteUrl,
    isArticle: true,
    image: SEOPropsFromPrismic.meta_image?.url || postData.hero_image?.url,
  };

  return {
    ...generalSEO,
    twitter: SEOPropsFromPrismic.card_type
      ? {
          cardType: SEOPropsFromPrismic.card_type,
          creator: SEOPropsFromPrismic.card_creator,
          site:
            SEOPropsFromPrismic.card_site || defaultMetadata.twitterUsername,
          title: SEOPropsFromPrismic.card_title || generalSEO.title,
          description:
            SEOPropsFromPrismic.card_description || generalSEO.description,
          image: SEOPropsFromPrismic.card_image?.url || generalSEO.image,
        }
      : undefined,
  };
};
