import styled from 'styled-components';
import { Container, GridItem, H3 } from '@tuunetech/tuune-components';

export const TopArticlesGridItem = styled(GridItem)`
  margin-top: 90px;

  ${H3} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    padding-left: 5%;

    ${H3} {
      margin-bottom: 27px;
    }
  }
`;

export const FeaturedContainer = styled.div`
  margin-bottom: 45px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 105px;
  }
`;

export const BlogLayoutContainer = styled(Container)`
  margin: 0 auto 80px;

  .MuiPaginationItem-root.Mui-selected {
    font-weight: bold;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 110px auto;
  }
`;
