import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  PostsListData,
  PrimaryPostData,
  HomepageData,
  SEODStaticData,
} from 'types';
import { HomepageLayout } from 'components/Homepage';
import { Layout } from 'components/shared';
import { constructSEO } from 'utils/seo';

declare global {
  interface Window {
    [key: string]: any;
  }
}

type HomepageContext = {
  numPages: number;
  currentPage: number;
};

type HomepageProps = {
  SEO: SEODStaticData;
} & HomepageData &
  PostsListData &
  PrimaryPostData;

const Homepage: React.FC<PageProps<HomepageProps, HomepageContext>> = ({
  location,
  data,
  pageContext,
}) => {
  const seoData = useMemo(
    () => constructSEO(data.prismicHomepage.data, data.SEO, location.pathname),
    [data.prismicHomepage.data, data.SEO, location.pathname],
  );

  return (
    <Layout path={location.pathname} seoData={seoData}>
      <HomepageLayout
        primaryPost={data.primaryPost}
        topArticles={data.prismicHomepage.data.top_articles.map(
          ({ article }) => article,
        )}
        posts={data.allPrismicBlogPost.nodes}
        totalPages={pageContext.numPages}
        currentPage={pageContext.currentPage}
      />
    </Layout>
  );
};

export const blogQuery = graphql`
  query ($skip: Int!, $limit: Int!, $primaryPostId: String!) {
    allPrismicBlogPost(
      sort: { fields: data___release_date, order: DESC }
      filter: { id: { ne: $primaryPostId } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PostPreviewData
        data {
          hero_image {
            fluid(imgixParams: { fit: "crop", ar: "2.2:1" }, maxWidth: 385) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
      }
    }

    primaryPost: prismicBlogPost(id: { eq: $primaryPostId }) {
      ...PostPreviewData
      data {
        hero_image {
          fluid(imgixParams: { fit: "crop", ar: "1.7:1" }, maxWidth: 800) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
      }
    }

    prismicHomepage {
      _previewable
      data {
        top_articles {
          article {
            uid
            document {
              ... on PrismicBlogPost {
                id
                uid
                _previewable
                data {
                  category {
                    uid
                    document {
                      ... on PrismicCategory {
                        _previewable
                        data {
                          name
                        }
                        uid
                      }
                    }
                  }
                  title {
                    raw
                    text
                  }
                }
              }
            }
          }
        }
      }
      ...PrismicHomepageSeoData
    }

    SEO: site {
      siteMetadata {
        description
        siteUrl
        title
        twitterUsername
      }
    }
  }
`;

export default Homepage;
