import React from 'react';
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { Article } from 'types';
import { Container, Divider } from '@tuunetech/tuune-components';
import { PostPreview } from '../PostPreview';

export const SelectedArticles: React.FC<{ articles: Article[] }> = ({
  articles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Container disableGutters>
      {articles.map(({ document: { data, uid } }) => (
        <React.Fragment key={uid}>
          <PostPreview
            title={data.title.text}
            path={`/${data.category.document.uid}/${uid}`}
            category={{
              name: data.category.document.data.name,
              path: data.category.document.uid,
            }}
            $marginBottom={isMobile ? 0 : 40}
          />
          <Hidden mdUp>
            <Divider $marginTop={25} $marginBottom={35} />
          </Hidden>
        </React.Fragment>
      ))}
    </Container>
  );
};
